<template>
  <div class="wrapper">
    <Menu></Menu>
    <Slider></Slider>
    <div class="body mt-1">
      <Highlights></Highlights>
      <Dropdown></Dropdown>
      <Games v-bind:sport="sport_id" v-show="current_sub_page !== 'countries' "></Games>
      <Countries v-show="current_sub_page === 'countries' "></Countries>
      <Trending class=""></Trending>
      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="home"></bottom-navigation>
    </div>

  </div>
</template>

<script>
import Highlights from './Highlights'
import Dropdown from './Dropdown'
import Slider from './Slider'
import Games from './Games'
import BottomFooter from './BottomFooter'
import Menu from './Menu'
import BottomNavigation from './BottomNavigation'
import Trending from './Trending'
import Countries from "@/components/Countries1";
import axios from "@/services/api";

export default {
  name: 'Share',
  components: {
    Menu,
    Slider,
    Highlights,
    Dropdown,
    Games,
    BottomFooter,
    BottomNavigation,
    Trending,
    Countries
  },
  data: function (){
    return {
      sub_page: '',
      booking_code: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","home");
    this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour",-1);
    this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID",0);

    this.autoRefreshUI(this.$vnode.tag);
    this.reloadProfile();

    this.booking_code = this.$route.params.code;
    console.log('got booking code '+this.booking_code);
    this.clearBetSlip();
    this.getPicks();

    var vm = this;

    this.EventBus.$on('share:code',function(code){

      vm.booking_code = code;
      console.log('got booking code '+vm.booking_code);
      vm.clearBetSlip();
      vm.getPicks();
    });


  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;
    }
  },
  methods: {
    getPicks: function () {

      var vm = this;
      var path = process.env.VUE_APP_URL_RETRIEVE_CODE;

      axios.post(path, JSON.stringify({code: this.booking_code}))
          .then(res => {

            var results = res.data.message;

            if(results.length === 0 ) {

              vm.setError("Invalid code","You have entered invalid or expired booking code")

            } else {

              vm.jQuery.each(results, function (k, v) {

                var sport_id = v.sport_id;
                var match_id = v.match_id;
                var market_name = v.market_name;
                var market_id = v.market_id;
                var away_team = v.away_team;
                var home_team = v.home_team;
                vm.addPick(sport_id, match_id, market_name, market_id, home_team, away_team, v);
              })

              vm.setValue("booking_code", vm.booking_code);
              vm.setValue("stake", 200);

              vm.EventBus.$emit('event:betslip:show');

            }

          })
          .catch(err => {

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  },
}
</script>