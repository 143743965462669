<template>

  <div class="">

    <div class="highlights padding-sides-md row">

      <div  v-for="(d,index) in dates" v-bind:key="index" class="highlight-text col-2 bold " style="text-align: center; padding: 0.2em 0;">
        <span
            v-bind:class="getMarketClass(d.date)"
            @click="setUpcomingDate(d.date,index,d.long)">
            {{ getDayName(index, d.label) }}
          </span>
      </div>

      <div  class="highlight-text col-2 bold " style="text-align: center; padding: 0.2em 0;">
        <svg viewBox="0 0 448 512" class="f" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12Zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12Zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12Zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12Zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12Zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12Zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48Zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6Z"></path></svg>
      </div>

      <!--

      <router-link
          to="/"
          class="highlight-text col-3 bold "
          v-bind:class="getPageActiveClass('home')"
          style="text-align: center; padding: 0.2em 0;">
        Highlights
      </router-link>

      <router-link
          to="/today"
          class="highlight-text col-3 bold"
          v-bind:class="getPageActiveClass('today')"
          style="text-align: center; padding: 0.2em 0;">
        Today
      </router-link>

      <router-link
          to="/upcoming"
          class="highlight-text col-3 bold"
          v-bind:class="getPageActiveClass('upcoming')"
          style="text-align: center; padding: 0.2em 0;">
        Upcoming
      </router-link>

      <router-link
          to="/countries"
          class="highlight-text col-3 bold"
          v-bind:class="getPageActiveClass('countries')"
          style="text-align: center; padding: 0.2em 0;">
        Countries
      </router-link>
      -->

    </div>

  </div>
</template>


<script>

export default {
  name: 'Highlights',
  data: function (){

    return {
      sub_page: '',
      child_page : '',
      dates: [],
      menu: 'all',
      maxDays: 5,
      date: '',
    }

  },
  methods: {

    setDate: function (date){

      this.$store.dispatch("setUpcomingDate", date.date)

    },

    setUpcomingDate: function (date,index,label) {

      this.menu = date;
      this.$store.dispatch("setUpcomingDate", date)
      this.selectedOption = this.getDayName(index, label)+'s Matches';

    },

    setUpcomingAll: function () {

      this.menu = 'all';
      this.$store.dispatch("setUpcomingDate", '');
      this.selectedOption = 'Upcoming Matches';
    },

    getDayName: function (i, label) {

      console.log('index ==> ' + i + ' label ==> ' + label);

      if (i === 0) {

        return 'Today';
      }

      if (i === 1) {

        // return 'Tomorrow';

      }

      return label;

    },

    setSubPage: function (page){

      var default_payload = this.$store.state.default_games_filter;
      var games_filter = this.$store.state.games_filter;

      if(page === "upcoming" ) {

        this.child_page = '';
        this.sub_page = page;
        this.$store.dispatch("setCurrentSubPage",page);

        if(games_filter.upcoming !== 1 ) {

          default_payload.upcoming = 1;
          default_payload.highlights = 0;
          default_payload.date = "";
          this.$store.dispatch("setGamesFilter",default_payload);

        }

      }
      else {

        this.$store.dispatch("setCurrentSubPage","upcoming");
        this.sub_page = "upcoming";

        if(games_filter.date !== page ) {

          this.child_page = page;

          default_payload.date = page
          default_payload.highlights = 0;
          default_payload.upcoming = 0;
          this.$store.dispatch("setGamesFilter", default_payload);

        }

      }

    },

    getSubPageActiveClass: function (subpage) {

      return subpage === this.$store.state.current_sub_page ? 'menu-highlighted' : "";

    },
    getPageActiveClass: function (page) {

      return page === this.$store.state.current_page ? 'menu-highlighted' : "";

    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },

    getMarketClass: function (menu) {

      return menu === this.menu ? 'market-list-active' : 'market-list';

    },

  },
  mounted: function () {

    var x = 0
    var vm = this;

    while (x < vm.maxDays) {

      var d0 = vm.daysFromNow(x);

      vm.dates.push({
        date: d0,
        label: vm.getDayOfWeek(d0),
        long: vm.getLongDayOfWeek(d0),
      });

      x++

    }
  },
  computed: {

    current_page: function () {

      return this.$store.state.current_page;
    }
  },

}
</script>

<style scoped>

.custom-divider {
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

</style>